import QRCode from './components/QRCode.svelte'

document.addEventListener('DOMContentLoaded', initQRCodes)

function initQRCodes() {
    const qrCodeElements = document.querySelectorAll('.qrcode-container')

    for (let qrCodeElement of qrCodeElements) {
        const props = qrCodeElement.dataset

        const qrCode = new QRCode({
            target: qrCodeElement,
            props
        })
    }
}
