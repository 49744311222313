document.addEventListener('DOMContentLoaded', () => {
    init()
})

function init () {
    const checkboxes = Array.from(document.querySelectorAll('.js-checkbox-require-one'))
    if (checkboxes.length === 0) { return }

    const form = checkboxes[0].closest('form')
    if (!form) { return }

    const message = form.querySelector('.js-checkbox-require-one-message')
    if (!message) { return }

    form.addEventListener('submit', (e) => {
        onFormSubmit(e, checkboxes, message)
    })
}

function onFormSubmit(e, checkboxes, message) {
    const valid = checkboxes.some(cb => cb.checked)

    if (valid) {
        if (message.classList.contains('js-checkbox-require-one-message--active')) {
            message.classList.remove('js-checkbox-require-one-message--active')
        }
    } else {
        if (!message.classList.contains('js-checkbox-require-one-message--active')) {
            message.classList.add('js-checkbox-require-one-message--active')
        }
        e.preventDefault()
    }
}
