import Quiz from './components/Quiz.svelte'

document.addEventListener('DOMContentLoaded', initQuiz)

function initQuiz() {
    const quizElement = document.querySelector('.quiz-container')
    if (!quizElement) { return }

    const props = quizElement.dataset

    const quiz = new Quiz({
        target: quizElement,
        props
    })
}
