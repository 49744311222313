<script>
    import { cart, addToCart, removeFromCart } from './store'

    export let textAdd = 'Add to Cart'
    export let textIn = 'In Cart'
    export let textRemove = 'Remove'
    export let itemId
    export let itemTitle
    export let itemUrl
    export let color = 'white'
    export let colorInverse = 'grey'

    let isItemInCart = false

    cart.subscribe(currentCart => {
        isItemInCart = !!currentCart[itemId]
    })

    function addItemToCart () {
        addToCart(itemId, itemTitle, itemUrl)
    }

    function removeItemFromCart () {
        removeFromCart(itemId)
    }
</script>

<style>
    .cart-button {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .text-in {
        padding-right: 1em;
    }

    .fa {
        margin-right: 0.2em;
    }
</style>

<div class="cart-button">
    {#if isItemInCart}
        <span class="fa fa-check" style={`color: ${colorInverse}`}></span>
        <span class="text-in" style={`color: ${colorInverse}`}>{textIn}</span>
        <button class="button" on:click={removeItemFromCart}>
            <span class="fa fa-trash"></span>
            {textRemove}
        </button>
    {:else}
        <button class="button is-primary" on:click={addItemToCart} style={`background-color: ${colorInverse}`}>
            <span class="fa fa-plus"></span>
            <span style={`color: ${color}`}>{textAdd}</span>
        </button>
    {/if}
</div>
