<script>
    import { cart, removeFromCart } from './store'

    export let textRemove = 'Remove'

    let currentCart = {}
    let items = []

    $: {
        items = Object.keys(currentCart).map(id => {
            const item = currentCart[id]
            item.id = id
            return item
        })
    }

    cart.subscribe(currCart => {
        currentCart = currCart
    })

    function removeItemFromCart (itemId) {
        removeFromCart(itemId)
    }
</script>

<style>
    .fa {
        margin-right: 0.2em;
    }

    li {
        margin-bottom: 1rem;
    }
</style>

<ul class="cart">
    {#each items as item}
    <li>
        <div class="level">
            <div class="level-left">
                <div class="level-item">
                    <a href={item.url}>{item.title}</a>
                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <button class="button" on:click={() => { removeItemFromCart(item.id) }}>
                    <span class="fa fa-trash"></span>
                        {textRemove}
                    </button>
                </div>
            </div>
        </div>
    </li>
    {:else}
        <li>Your wishlist is currently empty.</li>
    {/each}
</ul>
