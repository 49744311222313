import CartButton from './components/CartButton.svelte'
import CartLink from './components/CartLink.svelte'
import Cart from './components/Cart.svelte'
import CartFormFields from './components/CartFormFields.svelte'
import CartReset from './components/CartReset.svelte'
import CartShareLink from './components/CartShareLink.svelte'
import CartItemsFromUrl from './components/CartItemsFromUrl.svelte'

document.addEventListener('DOMContentLoaded', init)

function init () {
    initCartButtons()
    initCartLinks()
    initCarts()
    initCartFormFields()
    initCartResets()
    initCartShareLinks()
    initCartItemsFromUrl()
}

function initCartButtons () {
    const cartButtonElements = document.querySelectorAll('.cart-button-container')

    for (let cartButtonElement of cartButtonElements) {
        const props = cartButtonElement.dataset

        const cartButton = new CartButton({
            target: cartButtonElement,
            props
        })
    }
}

function initCartLinks () {
    const cartLinkElements = document.querySelectorAll('.cart-link-container')

    for (let cartLinkElement of cartLinkElements) {
        const props = cartLinkElement.dataset

        const cartLink = new CartLink({
            target: cartLinkElement,
            props
        })
    }
}

function initCarts () {
    const cartElements = document.querySelectorAll('.cart-container')

    for (let cartElement of cartElements) {
        const props = cartElement.dataset

        const cart = new Cart({
            target: cartElement,
            props
        })
    }
}

function initCartFormFields () {
    const cartFormFieldElements = document.querySelectorAll('.cart-form-fields-container')

    for (let cartFormFieldElement of cartFormFieldElements) {
        const props = cartFormFieldElement.dataset

        const cart = new CartFormFields({
            target: cartFormFieldElement,
            props
        })
    }
}

function initCartResets () {
    const cartResetElements = document.querySelectorAll('.cart-reset-container')

    for (let cartResetElement of cartResetElements) {
        const props = cartResetElement.dataset

        const cartReset = new CartReset({
            target: cartResetElement,
            props
        })
    }
}

function initCartShareLinks () {
    const cartShareLinkElements = document.querySelectorAll('.cart-share-link-container')

    for (let cartShareLinkElement of cartShareLinkElements) {
        const props = cartShareLinkElement.dataset

        const cartShareLink = new CartShareLink({
            target: cartShareLinkElement,
            props
        })
    }
}

function initCartItemsFromUrl () {
    const cartItemsFromUrlElements = document.querySelectorAll('.cart-items-from-url-container')

    for (let cartItemsFromUrlElement of cartItemsFromUrlElements) {
        const props = cartItemsFromUrlElement.dataset

        const cartItemsFromUrl = new CartItemsFromUrl({
            target: cartItemsFromUrlElement,
            props
        })
    }
}
