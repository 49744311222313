<script>
    import '../quizlib'
    import { onMount, tick } from 'svelte'

    export let baseUrl
    export let success
    let quiz
    let questions = []

    function initQuiz(questions) {
        const answers = questions.map(q => {
            if (q.correctAnswers.length === 1) {
                return q.correctAnswers[0]
            } else {
                return q.correctAnswers }
        })
        quiz = new Quiz('quiz', answers)

        window.quiz = quiz
    }

    async function fetchQuestions() {
        const relativePath = 'api/v1/quiz.json'
        const url = `${baseUrl}${relativePath}`
        const response = await fetch(url)
        const { data } = await response.json()
        return data[0]
    }

    onMount(async () => {
        questions = await fetchQuestions()
        await tick()
        initQuiz(questions)
    })

    function handleButtonClick() {
        if (success) {
            const res = window[success]()
        }
    }
</script>
<div id="quiz">
    <!-- Quiz Container -->

    {#each questions as q, i}
        {#if q.correctAnswers.length > 1}
            <!-- Checkbox Question -->
            <div class="card quizlib-question">
                <div class="quizlib-question-title">{q.question}</div>
                <div class="quizlib-question-answers">
                    <ul>
                        {#each q.answers as a}
                            <li><label><input type="checkbox" name="q{i}" value="{a.value}"> {a.label}</label></li>
                        {/each}
                    </ul>
                </div>
            </div>
        {:else}
            <!-- Radio Question -->
            <div class="card quizlib-question">
                <div class="quizlib-question-title">{q.question}</div>
                <div class="quizlib-question-answers">
                    <ul>
                        {#each q.answers as a, j}
                            <li><label><input type="radio" name="q{i}" value="{a.value}"> {a.label}</label></li>
                        {/each}
                    </ul>
                </div>
            </div>
        {/if}
    {/each}

    <!-- Answer Button -->
    <button type="button" class="quiz__button button is-primary" on:click="{handleButtonClick}">Submit</button>
</div>
<style>
    button {
        margin-top: 1rem;
    }
</style>