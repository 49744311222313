import { cart } from './components/store'

document.addEventListener('DOMContentLoaded', initWishlist)

function initWishlist () {
    const form = document.querySelector('.wishlist__form')
    if (!form) return

    setupCartEventListener({form})
}

function setupCartEventListener ({form}) {
    cart.subscribe(currCart => {
        checkAndShowForm({form, currCart})
    })
}

function checkAndShowForm ({form, currCart}) {
    if (isCartEmpty(currCart)) {
        showForm(form, false)
    } else {
        showForm(form, true)
    }
}

function isCartEmpty(currCart) {
    return Object.keys(currCart).length === 0
}

function showForm (form, show = true) {
    if (form.classList.contains('wishlist__form--hidden') && show) {
        form.classList.remove('wishlist__form--hidden')
    } else if (!form.classList.contains('wishlist__form--hidden') && !show) {
        form.classList.add('wishlist__form--hidden')
    }
}
