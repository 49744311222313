<script>
    import { cart } from './store'

    export let url
    export let title
    export let className

    let nItems = 1

    cart.subscribe(currentCart => {
        nItems = Object.keys(currentCart).length
    })

    export { className as class }
</script>

<style>
    .cart-link {
        display: inline-block;
        position: relative;
    }

    .fa {
        font-size: 1.4rem;
        margin-left: 1.4rem;
        color: #383d40;
    }

    .n-items {
        display: block;
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        background-color: #692a6c;
        color: white;
        font-size: 1rem;
        line-height: 0;
        font-weight: bold;
        padding: 0;
        padding-top: 0.7rem;
        padding-left: 0.4rem;
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 50%;
    }

    .cart-link__title {
        color: #383d40;
    }
</style>

<a href={url} class={`cart-link ${className}`}>
    {#if nItems > 0}
        <span class="n-items">{nItems}</span>
    {/if}
    <span class="fa fa-file-alt"></span>
    <span class="cart-link__title">{title}</span>
</a>
