function buildCartShareLink(baseUrl, relativePath, items) {
    if (!items || items.length === 0) { return null }

    const qs = items.map(item => item.id).map(id => `id[]=${id}`).join('&')

    return `${baseUrl}${relativePath}?${qs}`
}

export {
    buildCartShareLink
}
