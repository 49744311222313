<script>
    import { cart, addToCart } from './store'
    export let baseUrl
    export let wishlistRelativePath
    export let itemIdsStr
    let unsubscribe

    async function fetchCourses() {
        const relativePath = 'api/v1/courses.json'
        const url = `${baseUrl}${relativePath}`
        const response = await fetch(url)
        const { data } = await response.json()
        return data
    }

    function findCoursesByIds(courses, ids) {
        return courses.filter(course => ids.includes(course.id))
    }

    function addItemToCart(item) {
        const { id, title, url } = item
        addToCart(id, title, url)
    }

    function redirectToWishlist() {
        const url = `${baseUrl}${wishlistRelativePath}`
        window.location.replace(url)
    }

    unsubscribe = cart.subscribe(async (currentCart) => {
        if (itemIdsStr) {
            const ids = JSON.parse(itemIdsStr)
            const allCourses = await fetchCourses()
            const courses = findCoursesByIds(allCourses, ids)
            courses.forEach(course => addItemToCart(course))
            redirectToWishlist()
        }
        if(unsubscribe) { unsubscribe() }
    })
</script>
<div>Importing wishlist ...</div>
