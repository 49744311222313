import { writable } from 'svelte/store'
import produce from 'immer'

const createWritableStore = (key, startValue) => {
    const { subscribe, set, update } = writable(startValue);

    return {
        subscribe,
        set,
        update,
        useLocalStorage: () => {
            const json = localStorage.getItem(key);
            if (json) {
                set(JSON.parse(json))
            }

            subscribe(current => {
                localStorage.setItem(key, JSON.stringify(current))
            })
        }
    }
  }

const cart = createWritableStore('cart', {});
cart.useLocalStorage()

function addToCart (id, title, url) {
    cart.update(currCart => {
        const nextCart = produce(currCart, draftCart => {
            if (!currCart[id]) {
                draftCart[id] = { title, url }
            }
        })

        return nextCart
    })
}

function removeFromCart (id) {
    cart.update(currCart => {
        const nextCart = produce(currCart, draftCart => {
            if (currCart[id]) {
                delete draftCart[id]
            }
        })

        return nextCart
    })
}

function resetCart () {
    cart.update(currCart => {
        return {}
    })
}

export {
    cart,
    addToCart,
    removeFromCart,
    resetCart
}
