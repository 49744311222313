<script>
    import { cart, removeFromCart } from './store'

    export let textRemove = 'Remove'

    let currentCart = {}
    let items = []

    $: {
        items = Object.keys(currentCart).map(id => {
            const item = currentCart[id]
            item.id = id
            return item
        })
    }

    cart.subscribe(currCart => {
        currentCart = currCart
    })
</script>

{#each items as item}
    <input type="hidden" name="message[items][]" value={item.id}/>
{/each}
