<script>
     import { default as QrCode } from 'qrious'
    import { cart } from './store'
    import { buildCartShareLink } from '../cart-utils'

    export let baseUrl
    export let addItemsRelativePath
    export let qrSize

    const errorCorrection = "L";
    const background = "#fff";
    const color = "#000";
    const padding = 0;

    let currentCart = {}
    let items = []
    let url
    let image = ''
    const QRcode = new QrCode()

    $: {
        items = Object.keys(currentCart).map(id => {
            const item = currentCart[id]
            item.id = id
            return item
        })
        url = buildCartShareLink(baseUrl, addItemsRelativePath, items)
        if (url) {
            generateQrCode()
        }
    }

    function generateQrCode() {
        QRcode.set({
            background,
            foreground: color,
            level: errorCorrection,
            padding,
            size: qrSize,
            value: url,
        })

        image = QRcode.toDataURL('image/png');
    }

    cart.subscribe(currCart => {
        currentCart = currCart
    })
</script>

{#if url}
    <a href="{url}"><img src="{image}" alt="{url}" /></a>
{:else}
    <div></div>
{/if}
