<script>
    import { onMount } from 'svelte'
    import { default as QRious } from 'qrious'

    export let value
    export let className = ''
    export let size = "100"
    let image = ''
    const errorCorrection = "L";
    const background = "#fff";
    const color = "#000";
    const padding = 0;
    const code = new QRious()

    $: if (value && size) {
        generateQrCode()
    }

    function generateQrCode() {
        code.set({
            background,
            foreground: color,
            level: errorCorrection,
            padding,
            size,
            value,
        })

        image = code.toDataURL('image/png');
    }

    onMount(() => {
        generateQrCode()
    })
</script>
<img src="{image}" alt="{value}" class="{className}"  />
